
import { Options, Vue } from "vue-class-component";
import { showLoadingToast  } from 'vant';

@Options({
  props: {},
})
export default class App extends Vue {
  testButton() {
    console.log("testButton >>> ")
    showLoadingToast({
      message: '加载中...',
      forbidClick: true,
    });
  }
}
