const HttpStatusCode:any = {
    200: '[200]服务器成功返回请求的数据。',
    201: '[201]新建或修改数据成功。',
    202: '[202]一个请求已经进入后台排队（异步任务）。',
    204: '[204]删除数据成功。',
    400: '[400]发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '[401]用户没有权限（令牌、用户名、密码错误）。',
    403: '[403]用户得到授权，但是访问是被禁止的。',
    404: '[404]请求地址不存在!',
    406: '[406]请求的格式不可得。',
    410: '[410]请求的资源被永久删除，且不会再得到的。',
    422: '[422]当创建一个对象时，发生一个验证错误。',
    500: '[500]服务器发生错误，请检查服务器。',
    502: '[502]网关错误。',
    503: '[503]服务不可用，服务器暂时过载或维护。',
    504: '[504]网关超时。',
  };

  export default HttpStatusCode;
  