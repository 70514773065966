<template>
  <div class="login">
    <!-- 居中 -->
    <button
      @click="tohome"
      type="primary"
      size="large"
      style="
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      "
    >
      公众号授权登录
    </button>
  </div>
</template>
  <style scoped>
</style>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      gzhId: "wx521d6b9ba363ebcb", // 公众号appid
      // url: "https%3A%2F%2Ftest.h5.file.91soeasy.com.cn%2FwxLogin", // 授权回调链接
      url:
        process.env.NODE_ENV === "production"
          ? "https%3A%2F%2Fh5.file.91soeasy.com.cn%2FwxLogin"
          : "https%3A%2F%2Ftest.h5.file.91soeasy.com.cn%2FwxLogin",
    };
  },
  methods: {
    // 初始化效验
    init() {
      if (!this.getUrlKey("code")) {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.gzhId +
          "&redirect_uri=" +
          this.url +
          "&response_type=code&scope=snsapi_userinfo#wechat_redirect";
      } else {
        this.jumpBtn(this.getUrlKey("code"));
      }
    },
    // 跳转至小程序
    jumpBtn(val) {
      wx.miniProgram.postMessage({
        data: {
          code: val,
        },
      });
      wx.miniProgram.navigateBack({
        delta: 1,
      });
    },
    // 获取参数（http://www.sharedbk.com/post/188.html）
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
  created() {
    this.init(); // 初始化
  },
};
</script>
  
  