import UploadFileVue from '@/pages/uploadFile/uploadFile.vue'
import WxLoginVue from '@/pages/wxLogin/wxLogin.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/uploadFile',
    name: 'uploadFile',
    meta:{
      title:'选择文件类型'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:UploadFileVue
  },
  {
    path: '/wxLogin',
    name: 'wxLogin',
    meta:{
      title:'公众号授权登录'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:WxLoginVue
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.meta && to.meta.title)
  {
    document.title= to.meta.title as string;
  }
  else
  {
    document.title='简单91小程序'
  }
  next();
})

export default router
