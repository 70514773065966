/**
 * 上传文件的服务文件。 防止主文件过大，不好看
 */


function getFileName(file:any) {
	/**
	 * 获取文件的名称,上传图片，是获取不到名称的，需要创建一个随机的名称
	 * 
	 */
	let name = file.name;
	let path = file.path;
	if (name && name.trim() != '') // 有名称
	{
		return name.trim(); // 直接返回名称
	} else // 没有名称
	{
		if (path &&
			path.length > 0
		) {
			let extName = getFileExtName(path);
			if (extName) {
				return Date.now() + '.' + extName;
			}
		}
	}

	return null; // 没有名称
}

function getFileExtName(path:any) {
	/**
	 * 获取文件的扩展名，小写，不带 .
	 */
	let extName = path.split(".").pop();
	if (extName &&
		extName.trim() != '') {
		return extName.trim().toLowerCase();
	} else {
		return null;
	}
}

function validateFile(type:string|null, fileSize:number) {

	// console.log(`validateFile >>>> type=${type} fileSize=${fileSize}`)
	/**
	 * 验证文件文件类型和大小
	 * 1.判断是否是图片 image
	 * 2.判断是否 word
	 * 3.判断是否是 pdf
	 */
	if (!type) // 必须获取到文件类型
	{
		return "只能打开 '图像、word、pdf'文件~";
	} else if (!fileSize || fileSize <= 0) {
		return "没有获取到文件大小~";
	} else if (fileSize > 1024 * 1024 * 30) // 文件大小限制 30M
	{
		return "最大可以上传30M~";
	}
	return null; // 验证成功
}

const TypeEnum = {
	image: ['bmp', 'pjp', 'jpg', 'pjpeg', 'jpeg', 'jfif', 'png'],
	word: ['doc', 'docx'],
	pdf: ['pdf'],
	excelExts: ['xls', 'xlsx'],
	pptExts: ['ppt', 'pptx'],
}

function getFileType(ext:string|null) {
	// console.log("getFileType >>> ext", ext)
	if (ext && ext.trim() != '') {
		// 小写
		ext = ext.trim().toLowerCase();
		if (TypeEnum.image.indexOf(ext) >= 0) return 'image';
		else if (TypeEnum.word.indexOf(ext) >= 0) return 'word';
		else if (TypeEnum.pdf.indexOf(ext) >= 0) return 'pdf';
	}
	return null;
}

function newUploadKey() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}



export default {
	getFileName,
	getFileExtName,
	getFileType,
	validateFile,
	newUploadKey
}
