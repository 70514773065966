import  merge from "deepmerge"


export class ObjectUtil {
    /**
     合并两个对象
     参考: https://developer.aliyun.com/mirror/npm/package/deepmerge
     */
    public static deepMerge(x:Object, y:Object, options?:Object ): Object
    {
        return merge(x,y,options);
    }
    /**
    合并对个对象
    参考: https://developer.aliyun.com/mirror/npm/package/deepmerge
     */
    public static deepMergeAll(objArray:Object[],options?:Object ): Object
    {
        return merge(objArray,options??{});
    }

    /**
     * 浅合并(target 的字段会增加,即:会把source 的属性合并到target 中 )
     */
    public static shallowMerge(target:Object, source:Object) : Object
    {
        return Object.assign(target,source);
    }
}
