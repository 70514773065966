// import { BaseAxios, Result, ResultCode, TokenManager, RequestConfig } from 'tschris-utils';

import { AxiosResponse } from "axios";
import { AxiosAbstract, IAxiosRequestConfig, RequestConfig } from "./AxiosAbstract";
import { Result } from "./Result";

export async function getBaseURL():Promise<string>{
    return process.env.NODE_ENV === 'production'
    ?'https://wechat.91soeasy.com.cn'
    :"https://test.wechat.91soeasy.com.cn";

}

export class BaseApi extends AxiosAbstract {

    protected requestInterceptor(config: IAxiosRequestConfig):Promise<any> {
        return getBaseURL()
        .then(baseUrl=>{
            config.url =`${baseUrl}${config.url}`;
            config.timeout = 2 * 60 * 1000;  // 2分钟
            config.withCredentials = false;
            if(config.headers)
            {
                config.headers["inlet"] = 'yyt';
            }
            // console.log('requestInterceptor >> config == ',config)
        })
    }

    protected responseInterceptor(response: AxiosResponse<any, any>): Promise<any> {
        // console.log("responseInterceptor >> config=",response);
        return new Promise((resolve,reject)=>{
            let  result = response.data as Result<any>;
            if(result.success)
            {
                resolve(response.data);
            }
            else
            {
                reject(response.data);
            }
        })
    }

    protected responseError(error: any): Promise<any> {
        return new Promise((resolve, reject) => {
            // console.log('responseHandler >> ', error);
            reject(error);
        })
    }

}