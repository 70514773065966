import axios, { AxiosRequestHeaders,AxiosHeaders } from "axios";
import { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import Qs from "qs";
import { HttpContentType } from "./HttpContentType";
import { ObjectUtil } from "../util/ObjectUtil";
import TokenType from "./TokenType";
import HttpStatusCode from "./HttpStatusCode";
import { Result } from "./Result";

export interface IAxiosRequestConfig extends AxiosRequestConfig {
  token: TokenType /* 是否携带token . "auto": 自动;  true:携带token; false:不携带token  */;
}

export class RequestConfig implements IAxiosRequestConfig {
  public token: TokenType = TokenType.Auto;
  public url: string = "";
  public method?: Method;
  public baseURL: string = "";
  public params: object = {};
  public data: object = {};
  public timeout: number = 0;
  public headers: AxiosRequestHeaders = new AxiosHeaders();
  public withCredentials: boolean = false;
  public validateStatus(status: number) {
    return status >= 200;
  }
  //注意: 此处不应该有默认值=null; 否则post提交会产生提交参数data[object object]错误;
  public transformRequest?: ((formData: any) => void)[]; // =null;
}

export abstract class AxiosAbstract {
  constructor() {}
  protected abstract requestInterceptor(config: RequestConfig): Promise<any>;
  protected abstract responseInterceptor(
    response: AxiosResponse<any>
  ): Promise<any>;
  protected abstract responseError(response: any): Promise<any>;

  protected get<T>(
    url: any,
    params?: Object,
    optinons?: IAxiosRequestConfig
  ): Promise<any> {
    let config = new RequestConfig();
    config.headers["Content-Type"] = HttpContentType.APPLICATION_JSON_UTF8;
    config.url = url;
    config.method = "GET";
    config.params = params ?? {};

    return this.request<T>(config, optinons);
  }

  protected form<T>(
    url: any,
    data?: Object,
    optinons?: IAxiosRequestConfig
  ): Promise<any> {
    let config = new RequestConfig();
    config.headers["Content-Type"] =
      HttpContentType.APPLICATION_FORM_URLENCODED;
    config.url = url;
    config.method = "POST";
    config.data = data ?? {};

    config.transformRequest = [
      function (formData) {
        return Qs.stringify(formData);
      },
    ];

    return this.request<T>(config, optinons);
  }

  protected multipartForm<T>(
    url: any,
    data: FormData,
    optinons?: IAxiosRequestConfig
  ): Promise<any> {
    /**
        @Author: Chris 2020/05/07 13:22
        1 二进制上传文件
        前端
        const formData = new FormData();
        formData.append("taskId", dto.taskId);
        formData.append("chunk", dto.chunk.toString());
        formData.append("size", dto.size.toString());
        formData.append("chunkTotal", dto.chunkTotal.toString());
        formData.append("file", dto.file);

        后端:直接用对象接收就可以. 
        @PostMapping("chunkUpload")
        public Result fileChunkUpload(ChunkFileDto dto, HttpServletResponse response, HttpServletRequest request)
          throws Exception {

        3 也可以用
        @PostMapping("importExcel")
        public ResponseResult importExcel(
                @RequestParam(name = "file") MultipartFile file,
                @RequestParam(name = "paperId") Long paperId
        ) {
            paperService.importExcel(file,paperId);
            return ResponseResult.success(String.format("导入成功!"));
        }
    */
    let config = new RequestConfig();
    config.headers["Content-Type"] = HttpContentType.MULTIPART_FORM_DATA;
    config.url = url;
    config.method = "POST";
    config.data = data;
    return this.request<T>(config, optinons);
  }

  protected post<T>(
    url: any,
    data?: Object,
    optinons?: IAxiosRequestConfig
  ): Promise<any> {
    let config = new RequestConfig();
    config.headers["Content-Type"] = HttpContentType.APPLICATION_JSON_UTF8;
    config.url = url;
    config.method = "POST";
    config.data = data ?? {};

    return this.request<T>(config, optinons);
  }

  protected put<T>(
    url: any,
    data?: Object,
    optinons?: IAxiosRequestConfig
  ): Promise<any> {
    let config = new RequestConfig();
    config.headers["Content-Type"] = HttpContentType.APPLICATION_JSON_UTF8;
    config.url = url;
    config.method = "PUT";
    config.data = data ?? {};

    return this.request<T>(config, optinons);
  }

  protected delete<T>(
    url: any,
    data?: Object,
    optinons?: IAxiosRequestConfig
  ): Promise<any> {
    let config = new RequestConfig();
    config.headers["Content-Type"] = HttpContentType.APPLICATION_JSON_UTF8;
    config.url = url;
    config.method = "DELETE";
    config.data = data ?? {};

    return this.request<T>(config, optinons);
  }

   protected async request<T>(
    config: RequestConfig,
    optinons?: IAxiosRequestConfig
  ): Promise<any> {
    //合并选项
    if (optinons) {
      // config = ObjectUtil.deepMerge(config, optinons) as RequestConfig;
      // 传输header , 合并头文件
      if(optinons.headers)
      {
        config.headers =ObjectUtil.deepMerge(config.headers,optinons.headers) as AxiosRequestHeaders;
      }
    }
    // 使用请求拦截器
    await this.requestInterceptor(config).then(()=>{
      
    })

    //发送请求
    // console.log("BaseAxios.request>> config=", config);
    return this.requestHandler(config)
  }

  private requestHandler(config: RequestConfig):Promise<any>{
    return new Promise((resolve,reject)=>{

      // console.log("requestHandler  >>> config==",config)

      axios
      .request(config)
      .then((response) => {
        // HttpStatusCode
        let status= response.status;
        // 返回成功!
        if(status>=200 && status<300)
        {
          this.responseInterceptor(response)
          .then(data=>{
            resolve(data);
          })
          .catch(error=>{
            reject(error);
          })
        }
        else{
          let statusText=HttpStatusCode[status] || response.statusText
          // 返回错误!
          reject(Result.False(statusText).setData(response))
        }
      })
      .catch((error) => {
        // 进行错误拦截
        return this.responseError(error)
        .then(data=>{
          resolve(data);
        })
        .catch(error=>{
          reject(error);
        })
      });
    })
  }
}
