
import { Options, Vue } from "vue-class-component";
import { showToast, showLoadingToast } from 'vant';
import { orderApi } from '@/api/impl/orderApi'
import uploadFileService from './uploadFileService'

import $ from 'jquery';
import { LocationQueryValue } from "vue-router";
const FileAccept = {
	image: 'image/*',
	word: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	pdf: 'application/pdf',

}
export interface UploadFileDTO {
	name: string;
	path?: string;
	size: number;
	extName?: string;
	type?: string | null;
	uploadKey?: string;
	fileUploadSource: string;

}

@Options({
	components: {},
})
export default class UploadFileVue extends Vue {
	/**
 * 文件选择框<input type='file'> 的Id;
 */
	token: LocationQueryValue | LocationQueryValue[] = null;
	num: LocationQueryValue | LocationQueryValue[] = null;

	fileAccept = FileAccept.image;
	fileInputId = "INPUT_FILE_FILEDIALOG";


	fileIndex = 0;
	fileIndexFotIndex = 0;

	dataFileArray = []
	uploadWord() {
		this.fileAccept = FileAccept.word;
		// showToast("上传word")
		this.$nextTick(() => {
			this.$FileInput().click()
		});
	}

	uploadPdf() {
		this.fileAccept = FileAccept.pdf;
		// showToast("上传pdf")
		this.$nextTick(() => {
			this.$FileInput().click()
		});

	}

	private $FileInput() {
		return $(`#${this.fileInputId}`);
	}




	uploadFile(file: any) {
		let fileDto: UploadFileDTO = {
			name: file.name,
			path: '',
			size: file.size,
			fileUploadSource: 'MOBILE_DOCUMENT'
		}

		console.log("uploadFile >>> file=", file)
		/**
		 * 上传文件
		 * file={
			 name:"",
			 size:"",
			 path:""
			 
		 大纲: 
		 * 1.如果没有文件名，就随机产生一个文件名
		 * 2.获取文件扩展名 extName
		 * 3.获取文件类型 type 
		 * 3.验证文件类型和大小
		 * 4.生产上传文件的 uploadKey 值
		 * 5.上传文件
		 * 6.上传成功后，添加到打印列表中。
		 * 7.跳转到打印列表页面。 
		 }
		 */
		// 1.如果没有文件名，就随机产生一个文件名
		// 2.获取文件扩展名 extName
		fileDto.extName = uploadFileService.getFileExtName(fileDto.name);
		// 3.获取文件类型 type 
		fileDto.type = uploadFileService.getFileType(fileDto.extName ?? null);
		// 3.验证文件类型和大小
		let errorMsg = uploadFileService.validateFile(fileDto.type, fileDto.size);
		if (!!errorMsg) // 如果返回了错误信息,就进行提示
		{
			showToast({
				message: errorMsg
			})
		} else // 验证通过了。 
		{
			// 4.生产上传文件的 uploadKey 值
			fileDto.uploadKey = uploadFileService.newUploadKey();
			// 5.上传文件
			console.log("fileDto >>> ", fileDto)

			showLoadingToast({
				message: "处理中...",
				forbidClick: true,
			})
			orderApi.printMobileFileUpload({
				token: this.token?.toString(),
				file: file,
				data: fileDto
			})
				.then(res => {
					showToast("处理成功!")
					this.fileIndexFotIndex = (this.fileIndexFotIndex + 1)
					console.log("onChangeHandler.success >>> res ==", res);
					if (res && res.code == 0 &&
						res.success == true
					) {
						// var list = {
						// 	actions:'uploadSuccess',
						// 	fileDto:fileDto,
						// 	resultData: res.data
						// }
						// this.dataFileArray.push(list)

						(window as any).uni.postMessage({
							data: {
								actions: 'uploadSuccess',
								fileDto: fileDto,
								resultData: res.data
							}
						});
						// 跳转到列表页面。 
						// (window as any).uni.navigateTo({
						//     url: '/pages/copyprint/sendFileList/sendFileList?'
						// })
						// 回退一个消息
						if (this.fileIndexFotIndex == this.fileIndex) {
							console.log('我开始跳转呢');
							(window as any).uni.navigateBack({
								delta: 1
							});
						}
						// (window as any).uni.postMessage({
						//     data:{
						//         actions:'uploadSuccess',
						//         fileDto:fileDto,
						//         resultData: res.data
						//     }
						// });
						// // 跳转到列表页面。 
						// // (window as any).uni.navigateTo({
						// //     url: '/pages/copyprint/sendFileList/sendFileList?'
						// // })
						// // 回退一个消息
						// (window as any).uni.navigateBack({
						//     delta: 1
						// });
					}
					else {
						this.fileIndexFotIndex = (this.fileIndexFotIndex + 1)
						showToast(res.message)
					}

				})
				.catch(error => {
					this.fileIndexFotIndex = (this.fileIndexFotIndex + 1)
					showToast(error.message)
					console.log("onChangeHandler.error >>> error ==", error)
				})
		}
	}


	/**
	 * 选择文件后的处理方法
	 * @param event
	 */
	onChangeHandler(event: any) {
		/**
		 * 1. 获取要上传的文件
		 * 2. 对文件进行类型的验证
		 * 3. 开始上传
		 */
		// 1. 获取要上传的文件
		this.fileIndex = 0
		var files = event.target.files;
		console.log("onChange_file >> files=", files);
		if (
			files &&
			files.length > 0
		) {
			// 开始上传
			// this.uploadFile(files[0]);
			if (files.length > this.num!) {
				showToast("上传文件数量超出!，请上传数量小于" + this.num + "个文件")
			} else {
				this.fileIndex = files.length
				for (let i = 0; i < files.length; i++) {
					this.uploadFile(files[i]);
				}
			}
		} else {
			showToast("没有选择上传文件!")
		}
		// 清除
		this.$FileInput().val("");
	}
	mounted() {
		console.log("mounted >>>>>>>>>>>")
		// 获取url参数
		this.token = this.$route.query.token;
		this.num = this.$route.query.num;

		console.log("this.token >>> ", this.token, this.num)
		if (!this.token) {
			showToast("没有提供token")
		}
	}
}
