

export class Result<T> {
  public success: boolean = false;
  public data?: T;
  public code?: string;
  public message?: string;

  public setSuccess(success: boolean): Result<T> {
    this.success = success;
    return this;
  }

  public setData(data: T): Result<T> {
    this.data = data;
    return this;
  }

  public setMessage(message: string): Result<T> {
    this.message = message;
    return this;
  }

  public setCode(code: string): Result<T> {
    this.code = code;
    return this;
  }

  public static False<T>(message?: string, data?: T, code?: string) {
    return this.instance(false, message, data, code);
  }

  public static True<T>(message?: string, data?: T, code?: string) {
    return this.instance(true, message, data, code);
  }

  private static instance<T>(
    success?: boolean,
    message?: string,
    data?: T,
    code?: string
  ): Result<T> {
    let model: Result<T> = new Result<T>();
    model.success = success?true:false;
    model.message = message;
    model.data = data;
    model.code = code;
    return model;
  }

  public promise(): Promise<Result<any>> {
    return new Promise((resolve, reject) => {
      if (this.success) {
        resolve(this);
      } else {
        reject(this);
      }
    });
  }
}
