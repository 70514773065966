import { UploadFileDTO } from "@/pages/uploadFile/uploadFile";
import { RequestConfig } from "../AxiosAbstract";
import { BaseApi } from "../BaseApi";

class OrderApiImpl extends BaseApi implements IOrderApi {
    // #region 获取日期列表

    /**
     * 上传 文件
     * @param netStatus 上传
     * @returns 
     */
    public printMobileFileUpload(param:{
        token:string | undefined,
        file:any,
        data:UploadFileDTO
    }): Promise<any> {

        const formData = new FormData();

        formData.append("file", param.file);


        let fileName=param.data.name.replaceAll(' ','');
        let fileUploadSource=param.data.fileUploadSource
        formData.append("fileName", fileName);
        formData.append("fileUploadSource", fileUploadSource);
        
        // 配置token
        let config = new RequestConfig();
        config.headers["Access-token"] = param.token;
        
        return this.multipartForm(
            `/rih/yyt/order/print/mobile/file/upload?fileUploadSource=${fileUploadSource}&fileName=${fileName}`,
            formData,
            config
            );
    }

    // #endregion

    
}

export default interface IOrderApi {
    printMobileFileUpload(param:{
        token:string | undefined,
        file:any,
        data:UploadFileDTO
    }): Promise<any>
}

export const orderApi: IOrderApi = new OrderApiImpl()
